@import '../colors.scss';
@import '../fonts.scss';
#SearchBar {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    input {
        background-color: $transparent;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        border: none;
        box-sizing: border-box;
        font-size: 1.5rem;
        color: white;
        padding-left: 15px;
        padding-right: 15px;
    }
    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1;
        /* Firefox */
    }
    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
    }
    input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: white;
    }
}