@import url(https://fonts.googleapis.com/css?family=Alata&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alata&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Coolors Exported Palette - coolors.co/022f40-38aecc-0090c1-183446-046e8f */
/* HSL */
/* RGB */
/* Transparent */
* {
  font-family: 'Alata', sans-serif; }

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

button:active,
button:focus {
  outline: 0; }

#Header {
  background: #38aecc;
  background: linear-gradient(144deg, #38aecc 0%, #046e8f 100%); }

#Viewer {
  display: flex;
  place-content: center;
  flex-direction: row; }
  #Viewer button {
    background: none;
    border: solid 1px black;
    font-size: 2em;
    padding: 20px;
    height: 400px;
    align-self: center; }

.react-pdf__Page {
  position: relative;
  place-content: center;
  display: flex; }

.react-pdf__Page__canvas {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin: 50px; }

/* Coolors Exported Palette - coolors.co/022f40-38aecc-0090c1-183446-046e8f */
/* HSL */
/* RGB */
/* Transparent */
#SearchResults {
  width: auto;
  position: absolute;
  top: 75px;
  left: 0;
  background-color: #183446;
  right: 0; }
  #SearchResults div.result {
    background-color: #046e8f;
    height: 50px;
    display: grid;
    grid-template-columns: 200px 200px auto;
    padding: 0px 100px;
    margin: 15px;
    cursor: pointer; }
    #SearchResults div.result div {
      color: white;
      place-self: center; }
  #SearchResults a {
    text-decoration: none; }

/* Coolors Exported Palette - coolors.co/022f40-38aecc-0090c1-183446-046e8f */
/* HSL */
/* RGB */
/* Transparent */
* {
  font-family: 'Alata', sans-serif; }

#SearchBar {
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }
  #SearchBar input {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    border-radius: 0px;
    border: none;
    box-sizing: border-box;
    font-size: 1.5rem;
    color: white;
    padding-left: 15px;
    padding-right: 15px; }
  #SearchBar input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  #SearchBar input::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  #SearchBar input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  #SearchBar input::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  #SearchBar input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  #SearchBar input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white; }
  #SearchBar input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white; }

.course-section .course-grid {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 25px 150px; }
  .course-section .course-grid .filename {
    text-align: left;
    height: 200px;
    border: solid 1px black;
    margin: 25px;
    box-sizing: border-box;
    display: flex;
    place-items: center;
    place-content: center;
    cursor: pointer; }

.course-section .course-header {
  text-align: left;
  padding: 25px 100px;
  display: flex; }
  .course-section .course-header hr {
    border: none;
    border-top: 1px solid red;
    margin-top: 11px;
    margin-left: 11px;
    flex: 1 1; }

.course-section.collapsed .course-grid {
  display: none; }

/* Coolors Exported Palette - coolors.co/022f40-38aecc-0090c1-183446-046e8f */
/* HSL */
/* RGB */
/* Transparent */
#HomeButton {
  display: flex;
  place-content: center;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box; }

