@import "../colors.scss";
#SearchResults {
    width: auto;
    position: absolute;
    top: 75px;
    left: 0;
    background-color: $color4;
    right: 0;
    div.result {
        background-color: $color5;
        height: 50px;
        display: grid;
        grid-template-columns: 200px 200px auto;
        padding: 0px 100px;
        margin: 15px;
        cursor: pointer;
        div {
            color: white;
            place-self: center;
        }
    }
    a {
        text-decoration: none;
    }
}