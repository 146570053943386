@import './colors.scss';
@import './fonts.scss';
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

button:active,
button:focus {
    outline: 0;
}

#Header {
    background: $color2;
    background: linear-gradient(144deg, $color2 0%, $color5 100%);
}

#Viewer {
    display: flex;
    place-content: center;
    flex-direction: row;
    button {
        background: none;
        border: solid 1px black;
        font-size: 2em;
        padding: 20px;
        height: 400px;
        align-self: center;
    }
}

.react-pdf__Page {
    position: relative;
    place-content: center;
    display: flex;
}

.react-pdf__Page__canvas {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 50px;
}