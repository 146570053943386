/* Coolors Exported Palette - coolors.co/022f40-38aecc-0090c1-183446-046e8f */


/* HSL */

$color1: hsla(196%, 94%, 13%, 1);
$color2: hsla(192%, 59%, 51%, 1);
$color3: hsla(195%, 100%, 38%, 1);
$color4: hsla(203%, 49%, 18%, 1);
$color5: hsla(194%, 95%, 29%, 1);

/* RGB */

$color1: rgba(2, 47, 64, 1);
$color2: rgba(56, 174, 204, 1);
$color3: rgba(0, 144, 193, 1);
$color4: rgba(24, 52, 70, 1);
$color5: rgba(4, 110, 143, 1);
$color3-03: hsla(195%, 100%, 38%, 0.3);

/* Transparent */

$transparent: rgba(0, 0, 0, 0);